/**
 * Create a fetch cache that caches the results of a fetch call based on a key.
 * @returns a function that takes a key and a fetcher and returns a promise that resolves to the result of the fetch call.
 */
export function createFetchCache<T>() {
  const cache = new Map<string, Promise<T>>();
  const fetch = (key: string, fetcher: () => Promise<T>) => {
    const promise = cache.get(key) || fetcher();
    cache.set(key, promise);
    return promise;
  };

  return fetch;
}
