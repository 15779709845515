import * as Popover from "@radix-ui/react-popover";
import { forwardRef, useRef, useState } from "react";
import { Text } from "../text/text";
import { type TagProps, Tag as TagComp } from "./tag";
import { cn } from "../utils";

interface Tag {
  name: string;
  iconSrc?: string | null;
  displayName?: string | null;
}

interface TagComponentProps extends Omit<TagProps, "children"> {
  tag: Tag;
  onCloseClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

/** Wrapper for our existing Tag component that handles things like optional prefix images and close buttons */
export const TagComponent = forwardRef(
  ({ tag, onCloseClick, ...props }: TagComponentProps, ref?: React.Ref<HTMLDivElement>) => {
    const displayName = "displayName" in tag && tag.displayName ? tag.displayName : tag.name;

    return (
      <TagComp
        ref={ref}
        className={cn("hover:bg-muted-foreground/10", props.className)}
        prefix={tag.iconSrc ? <img className="h-4 w-4" alt={`${displayName} logo`} src={tag.iconSrc} /> : undefined}
        {...props}
      >
        <div className="flex whitespace-nowrap">
          {displayName}
          {onCloseClick && (
            <button className="ml-1" onClick={onCloseClick} aria-label="Remove tag">
              &times;
            </button>
          )}
        </div>
      </TagComp>
    );
  },
);

interface TaggingInterfaceProps {
  autoCompleteSuggestions?: Tag[];
  className?: string;
  maxTags?: number;
  onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onTagsChange: (tags: Tag[]) => void;
  suggestedTags?: Tag[] | null | undefined;
  tags: Tag[];
}

/**
 * @summary A component that allows users to add tags to an input field.
 * @description This component provides an interface for adding tags, displaying suggested tags, offering autocomplete suggestions, and limiting the number of tags that can be added. Note that typing a space character will add the current input as a tag.
 * @param {TaggingInterfaceProps} props - The properties for the TaggingInterface component.
 * @param {Tag[]} [props.autoCompleteSuggestions] - An array of tags that will be displayed as a dropdown menu beneath the input field.
 * @param {number} [props.maxTags] - The maximum number of tags that can be added. If not provided, there is no limit.
 * @param {function} [props.onInputChange] - A function that will be called when the user changes the input field.
 * @param {function} props.onTagsChange - A function that will be called when the user adds or removes a tag.
 * @param {Tag[]} [props.suggestedTags] - An array of tags that will be displayed as suggestions beneath the input field.
 * @param {Tag[]} props.tags - The current tags that have been added.
 */
export const TaggingInterface: React.FC<TaggingInterfaceProps> = ({
  autoCompleteSuggestions,
  className,
  maxTags,
  onInputChange,
  onTagsChange,
  suggestedTags,
  tags,
}) => {
  const [input, setInput] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState(-1);
  const inputRef = useRef<HTMLInputElement>(null);

  const addTag = (tag: Tag) => {
    if (!tags.some((t) => t.name === tag.name) && (maxTags ? tags.length < maxTags : true)) {
      const newTags = [...tags, tag];
      onTagsChange(newTags);
    }

    setShowSuggestions(false);
    setActiveSuggestion(-1);
    setInput("");
    inputRef.current?.focus();
  };

  const addTagFromInput = () => {
    const trimmedInput = input.trim();
    if (trimmedInput) {
      addTag({ name: trimmedInput });
    }
  };

  const removeTag = (idx: number) => {
    const newTags = tags.filter((_, i) => i !== idx);
    onTagsChange(newTags);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInput(value);
    onInputChange?.(e);
    setShowSuggestions(true);
    setActiveSuggestion(-1);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (autoCompleteSuggestions && activeSuggestion !== -1 && autoCompleteSuggestions[activeSuggestion]) {
        addTag(autoCompleteSuggestions[activeSuggestion]);
      } else {
        addTagFromInput();
      }
    } else if (e.key === " ") {
      e.preventDefault();
      addTagFromInput();
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      if (autoCompleteSuggestions) {
        setActiveSuggestion((prev) => (prev > 0 ? prev - 1 : autoCompleteSuggestions.length - 1));
      }
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      if (autoCompleteSuggestions) {
        setActiveSuggestion((prev) => (prev < autoCompleteSuggestions.length - 1 ? prev + 1 : 0));
      }
    }
  };

  return (
    <div className={className || ""}>
      <div className="flex flex-wrap gap-y-2 items-center pb-2 border border-gray-200 rounded-md p-3">
        {tags.map((tag, idx) => (
          <TagComponent
            key={tag.name}
            tag={tag}
            onCloseClick={(e) => {
              e.preventDefault();
              removeTag(idx);
            }}
            className="tag mx-1"
          />
        ))}
        <Popover.Root open={showSuggestions} onOpenChange={setShowSuggestions} modal={true}>
          {(!maxTags || tags.length < maxTags) && (
            <Popover.Anchor>
              <input
                ref={inputRef}
                type="text"
                value={input}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                placeholder="Add a tag..."
                className={`border-none outline-none border-transparent focus:border-transparent focus:ring-0 py-0 w-32 bg-transparent text-xs pb-1 ${tags.length === 0 ? "pl-0" : ""}`}
              />
            </Popover.Anchor>
          )}
          {showSuggestions && input && autoCompleteSuggestions && autoCompleteSuggestions.length > 0 && (
            <Popover.Portal>
              <Popover.Content
                onOpenAutoFocus={(e) => e.preventDefault()}
                align="start"
                className="z-50 pointer-events-auto"
              >
                <ul className="border border-gray-300 rounded-md bg-background">
                  {autoCompleteSuggestions.map((suggestion, index) => (
                    <li
                      key={suggestion.name}
                      className={`${index === activeSuggestion ? "bg-gray-200" : ""} cursor-pointer px-2 py-1 hover:bg-gray-200 whitespace-nowrap`}
                      onClick={() => addTag(suggestion)}
                    >
                      <Text size="sm" className="text-dashboard-text">
                        {suggestion.displayName || suggestion.name}
                      </Text>
                    </li>
                  ))}
                </ul>
              </Popover.Content>
            </Popover.Portal>
          )}
        </Popover.Root>
      </div>
      <div className="flex mt-2 flex-wrap gap-y-2 pb-1 items-center min-h-7">
        <Text size="sm" color="muted">
          Suggested Tags:
        </Text>
        {suggestedTags?.map((tag) => (
          <TagComponent
            key={tag.name}
            className="mx-1 cursor-pointer hover:bg-muted-foreground/30"
            onClick={() => addTag(tag)}
            tag={tag}
          />
        ))}
      </div>
    </div>
  );
};
