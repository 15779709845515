import type { Tag, TagResourceType } from "@fscrypto/domain/tag";
import { useStore } from "@fscrypto/state-management";
import { createFetchCache } from "~/utils/fetch-cache";
import { tagsClient } from "../data/tags-client";
import { tagStore, updateTagStore } from "../state/tags";
import type { UITag } from "../ui/tagging-interface";

const fetchTags = createFetchCache<{ tags: UITag[] }>();
const fetchSuggested = createFetchCache<{ tags: UITag[] }>();

/** Functionality for working with user generated resource tags */
export const useTags = (
  resourceType: TagResourceType,
  resourceId: string,
  config?: { data?: Tag[]; skipFetch?: boolean },
) => {
  const allTags = useStore(tagStore);
  const tags = allTags[resourceId];

  // If data is provided, merge it with the existing tags
  if (config?.data) {
    const mergedTags = [
      ...(tags || []).filter((tag) => !config?.data?.find((newTag) => "id" in tag && newTag.id === tag.id)),
      ...config.data,
    ];
    updateTagStore(resourceId, mergedTags);
  }

  // If this is the initial setup of this hook and no data is provided, fetch the tags
  if (!tags && !config?.data && !config?.skipFetch) {
    fetchTags(resourceId, () => tagsClient.get(resourceType, resourceId)).then(({ tags }) => {
      updateTagStore(resourceId, tags);
    });
  }

  const set = (tags: UITag[]) => {
    updateTagStore(resourceId, tags);
  };

  const search = (query: string) => {
    return tagsClient.search(query);
  };

  const getSuggested = () => {
    return fetchSuggested(resourceId, () => tagsClient.getSuggested(resourceType, resourceId));
  };

  return {
    /** NOTE: This only updates the local store, it does not persist to the database. To do that, use the tagsClient */
    set,
    tags,
    search,
    getSuggested,
  };
};
